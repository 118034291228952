import { graphql } from 'gatsby';
import React from 'react';

import PageSEO from '../components/PageSEO';
import Layout from '../components/ui/Layout';
import '../fragments.ts';

import { HeaderProps } from '../components/ui/Header';
import Section, { SectionProps } from '../components/ui/Section';
import { LocalizedSEO } from '../fragments';
import '../styles/main.scss';

export const query = graphql`
  query GetPlansPageById($id: String!) {
    sanityLpViralSettings {
      header {
        signInButtonText
        anchorButtonText
      }
    }
    sanityLpViralPlansPage(_id: { eq: $id }) {
      sections {
        title
        useTitleOnlyAsLabel
        description
        content {
          __typename
          ...Modules
        }
      }
      seo {
        ...SEO
      }
    }
  }
`;

interface PlansPageProps {
  data: {
    sanityLpViralSettings: {
      header: HeaderProps;
    };
    sanityLpViralPlansPage: {
      sections: Array<SectionProps>;
      seo: LocalizedSEO;
    };
  };
}

const PlansPage = ({ data }: PlansPageProps): React.ReactElement => {
  const { sections, seo } = data.sanityLpViralPlansPage;
  const { header } = data.sanityLpViralSettings;

  return (
    <Layout signInButtonText={header.signInButtonText} anchorButtonText={header.anchorButtonText}>
      <PageSEO defaultTitle={'Plans'} pageSEO={seo} />

      {sections.map((section, sectionIndex) => (
        <Section
          key={sectionIndex}
          {...section}
          sectionIndex={sectionIndex}
          lastSection={sectionIndex === sections.length - 1}
        ></Section>
      ))}
    </Layout>
  );
};

export default PlansPage;
